/* eslint-disable sort-imports */
import { AppInsightsContext, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { serviceIdentifiers } from "./serviceContainer/ServiceIdentifiers";
import type { Provider as BindingProvider } from "./serviceContainer/ServiceIdentifiers";
import { Container, inject, injectable } from "inversify";
import { InversifyProvider, serviceContainerProvider } from "./serviceContainer/ServiceContainerProvider";
import { loadDeviceConfig, setBuildingToDefaultId, setCurrentUser } from "./redux/Actions";
import { sagaMiddleware, store } from "./redux/Store";
import { App } from "./App";
import { AppState } from "./redux/Types/AppMetricTypes";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import { FeatureFlightingContext } from "@smartbuilding/feature-flighting-service-react";
import type { IAuthClient } from "@smartbuilding/auth-client";
import { IDIPService } from "./services/DIPService/DIPService";
import { IFeatureFlightingService } from "@smartbuilding/feature-flighting-service";
import { IHealthCheckService } from "./services/HealthCheckService";
import type { ILogger } from "@smartbuilding/log-provider";
import { Provider } from "react-redux";
import React from "react";
import { RootSaga } from "./redux/Sagas/RootSaga";
import { ThemeProvider } from "./theme-provider/ThemeProvider";
import { createAppStateUpdateAction } from "./redux/Actions/AppMetricsActions";
import { electronService } from "@smartbuilding/electron-service";
import { initializeIcons } from "@uifabric/icons";
import { render } from "react-dom";
import { setBootstrapperError } from "./redux/Actions/ErrorActionCreator";

@injectable()
export class AppBootstrapper {
  public constructor(
    // @ts-ignore
    @inject(serviceIdentifiers.authClient) private authClient: IAuthClient,
    // @ts-ignore
    @inject(serviceIdentifiers.rootSaga)
    private rootSagaProvider: BindingProvider<RootSaga>,
    // @ts-ignore
    @inject(serviceIdentifiers.inversifyContainer) private container: Container,
    // @ts-ignore
    @inject(serviceIdentifiers.telemetryReactPlugin) private reactPlugin: ReactPlugin,
    // @ts-ignore
    @inject(serviceIdentifiers.healthCheckService)
    private healthCheckService: BindingProvider<IHealthCheckService>,
    // @ts-ignore
    @inject(serviceIdentifiers.featureFlightingService)
    private featureFlightingService: BindingProvider<IFeatureFlightingService>,
    // @ts-ignore
    @inject(serviceIdentifiers.logger) private logger: ILogger,
    // @ts-ignore
    @inject(serviceIdentifiers.dipService) private dipService: BindingProvider<IDIPService>
  ) {}

  public async startApp(): Promise<void> {
    try {
      // Making sure we only run in the main window and not in an iframe created by ADAL when acquiring tokens.
      if (window === window.parent && window === window.top) {
        await this.authClient.initialize();
        const rootSaga = await this.rootSagaProvider();
        const dipService = await this.dipService();

        await this.renderApp();
        serviceContainerProvider.loadContainer(this.container);
        sagaMiddleware.run(rootSaga.start);
        store.dispatch(createAppStateUpdateAction(AppState.Loading));
        await dipService.initialize();

        if (electronService.isElectron()) {
          const healthService = await this.healthCheckService();
          healthService.startServices();
          store.dispatch(loadDeviceConfig());
        } else {
          const url = new URL(window.location.href);
          const urlParams = new URLSearchParams(url.search);
          const buildingName = urlParams.get("buildingName");
          const floorName = urlParams.get("floorName");
          const roomName = urlParams.get("roomName");
          const userEmail = await this.authClient.getUser();
          const userName = await this.authClient.getName();
          if (buildingName) {
            store.dispatch(setBuildingToDefaultId(userEmail, buildingName, floorName, roomName));
          } else {
            store.dispatch(setBuildingToDefaultId(userEmail));
          }
          store.dispatch(setCurrentUser(userEmail, userName));
        }
        this.logger.logEvent("[AppBootstrapper] Application launched");
      }
    } catch (error) {
      this.handleError(error instanceof Error ? error : new Error("[AppBootstrapper] Failed to start: unknown error"));
    }
  }

  private async renderApp(): Promise<void> {
    initializeIcons();
    render(
      <ThemeProvider>
        <InversifyProvider container={this.container}>
          <AppInsightsContext.Provider value={this.reactPlugin}>
            <FeatureFlightingContext.Provider value={await this.featureFlightingService()}>
              <Provider store={store}>
                <App />
              </Provider>
            </FeatureFlightingContext.Provider>
          </AppInsightsContext.Provider>
        </InversifyProvider>
      </ThemeProvider>,
      document.getElementById("react-app")
    );
  }

  private handleError(error: Error): void {
    store.dispatch(
      setBootstrapperError({
        message: error.message,
        stack: error.stack
      })
    );
    render(
      <ThemeProvider>
        <Provider store={store}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Provider>
      </ThemeProvider>,
      document.getElementById("react-app")
    );
  }
}
