import React from "react";
import { createSvgIcon } from "@fluentui/react-icons-mdl2";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SofaOutlineIcon = createSvgIcon({
  // eslint-disable-next-line react/display-name
  svg: (args) => {
    return (
      <svg viewBox="0 0 24 24" fill="none" className={args.classes.svg} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.25 4C5.73122 4 4.5 5.23122 4.5 6.75V8.08697C3.06665 8.42555 2 9.71321 2 11.25V14.25C2 15.6845 3.09837 16.8625 4.5 16.9888V18.25C4.5 18.6642 4.83579 19 5.25 19C5.66421 19 6 18.6642 6 18.25V17H18V18.25C18 18.6642 18.3358 19 18.75 19C19.1642 19 19.5 18.6642 19.5 18.25V16.9888C20.9016 16.8625 22 15.6845 22 14.25V11.25C22 9.71321 20.9333 8.42555 19.5 8.08697V6.75C19.5 5.23122 18.2688 4 16.75 4H7.25ZM19.25 15.5H4.75C4.05964 15.5 3.5 14.9404 3.5 14.25V11.25C3.5 10.2835 4.2835 9.5 5.25 9.5C6.2165 9.5 7 10.2835 7 11.25V11.75C7 12.1642 7.33579 12.5 7.75 12.5H16.25C16.6642 12.5 17 12.1642 17 11.75V11.25C17 10.2835 17.7835 9.5 18.75 9.5C19.7165 9.5 20.5 10.2835 20.5 11.25V14.25C20.5 14.9404 19.9404 15.5 19.25 15.5ZM18 8.08697C16.6451 8.40702 15.6179 9.57509 15.5095 11H8.49053C8.38213 9.57509 7.35489 8.40702 6 8.08697V6.75C6 6.05964 6.55964 5.5 7.25 5.5H16.75C17.4404 5.5 18 6.05964 18 6.75V8.08697Z"
          fill="currentColor"
        />
      </svg>
    );
  },
  displayName: "SofaOutlineIcon"
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SofaFilledIcon = createSvgIcon({
  // eslint-disable-next-line react/display-name
  svg: (args) => {
    return (
      <svg viewBox="0 0 24 24" fill="none" className={args.classes.svg} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.75 4H7.25C5.73122 4 4.5 5.23122 4.5 6.75V8C6.26324 8 7.72194 9.30385 7.96456 11H16.0354C16.2781 9.30385 17.7368 8 19.5 8V6.75C19.5 5.23122 18.2688 4 16.75 4ZM17 11.5C17 10.1193 18.1193 9 19.5 9C20.8807 9 22 10.1193 22 11.5V14.25C22 15.6845 20.9016 16.8625 19.5 16.9888V18.25C19.5 18.6642 19.1642 19 18.75 19C18.3358 19 18 18.6642 18 18.25V17H6V18.25C6 18.6642 5.66421 19 5.25 19C4.83579 19 4.5 18.6642 4.5 18.25V16.9888C3.09837 16.8625 2 15.6845 2 14.25V11.5C2 10.1193 3.11929 9 4.5 9C5.88071 9 7 10.1193 7 11.5C7 11.7761 7.22386 12 7.5 12H16.5C16.7761 12 17 11.7761 17 11.5Z"
          fill="currentColor"
        />
      </svg>
    );
  },
  displayName: "SofaFilledIcon"
});
