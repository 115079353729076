import { FloorSelector, ZoomControl } from "@smartbuilding/ui-components-map-controls";
import {
  IIndoorAzureMap,
  IIndoorMapOptions,
  IOutdoorAzureMap,
  MapEvent,
  MapStyles,
  OutdoorAzureMap
} from "@smartbuilding/azure-maps";
import { IMapControlBaseProps, IMapControlStyleProps, IMapControlStyles } from "./MapControl.Types";
import { IRoomInfo, ISpaceInfo } from "../../../redux/Types";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  clearPoiLayer,
  clearSpacePinsLayer,
  renderSpacePinsLayer,
  setFloor,
  setResetInProgress,
  spaceClickedAction
} from "../../../redux/Actions";
import {
  getBuildingCategories,
  getBuildingMapData,
  getDeepLinkFloorId,
  getDeviceConfigBearing,
  getFloorId,
  getFloors,
  getResetInProgress,
  getRoomMap,
  getSelectedCategory
} from "../../../redux/Selectors";
import { getIsMenuPanelOpen, getIsZoomed } from "../../../redux/Selectors/LayoutSelectors";
import { startTrackingEventAction, stopTrackingEventAction } from "../../../redux/Actions/AppMetricsActions";
import { useDispatch, useSelector } from "react-redux";
import { IAzureMapsDataQueryResponse } from "@smartbuilding/smartbuilding-api-service";
import { IObserver } from "@smartbuilding/utilities";
import { MapErrorObserver } from "../../../utilities/Observers/MapErrorObserver";
import ReactDOM from "react-dom";
import { classNamesFunction } from "@fluentui/react";
import { combineCategorySpaceId } from "../../DetailsPanel/ListViewCard/useListViewCardUtilities";
import { serviceIdentifiers } from "../../../serviceContainer/ServiceIdentifiers";
import { useFeatureFlightingValues } from "../../../hooks/useFeatureFlightingValues";
import { useInjection } from "../../../serviceContainer/ServiceContainerProvider";
import { useMapLayers } from "./useMapLayers";

const containerElemId = "react-azure-maps-container";
const mapInitializationEvent = "Map Initialization Event";
const getClassNames = classNamesFunction<IMapControlStyleProps, IMapControlStyles>();
const indoorZoomLevel = 19;
const outdoorZoomLevel = 10;
const isIndoor = true;
const zoomlevel = isIndoor ? indoorZoomLevel : outdoorZoomLevel;
const duplicatedElements = ["atlas-map-style", "atlas-map-state", "atlas-map-shortcuts"];

interface IInitializedBuilding {
  tilesetId: string;
  floors: Set<string>;
}

export function MapControlBase(props: IMapControlBaseProps): JSX.Element {
  const { configurationService, azureMapsTokenService, theme, logger, smartBuildingApiHttpProvider } = props;
  const outdoorAzureMaps = useRef<IOutdoorAzureMap | null>(null);
  const indoorAzureMaps = useRef<IIndoorAzureMap | null>(null);
  const clientTheme = theme.isInverted ? MapStyles.Light : MapStyles.Dark;
  const floorId = useSelector(getFloorId);
  const deepLinkFloorId = useSelector(getDeepLinkFloorId);
  const bearing = useSelector(getDeviceConfigBearing);
  const mapData = useSelector(getBuildingMapData);
  const floors = useSelector(getFloors);
  const buildingCategories = useSelector(getBuildingCategories);
  const spaceMap = useSelector(getRoomMap);
  const selectedCategory = useSelector(getSelectedCategory);
  const floorsRef = useRef(floors);
  const floorRef = useRef<ISpaceInfo | undefined>(undefined);
  const mapInitialized = useRef(false);
  const dispatch = useDispatch();
  const [numberOfRoomsPerFloor, setNumberOfRoomsPerFloor] = useState<Map<string, number> | undefined>(undefined);
  const isZoomed = useSelector(getIsZoomed);
  const isMenuPanelOpen = useSelector(getIsMenuPanelOpen);
  const featureFlightingValues = useFeatureFlightingValues(logger);
  const mapErrorObserver = useInjection<MapErrorObserver>(serviceIdentifiers.mapObserver);
  const initializedBuildingRef = useRef<IInitializedBuilding | undefined>(undefined);
  const resetInProgress = useSelector(getResetInProgress);

  const classNames = getClassNames(props.styles, {
    theme: theme,
    isZoomed,
    isMenuPanelOpen
  });

  useEffect(() => {
    if (!selectedCategory) {
      setNumberOfRoomsPerFloor(undefined);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedCategory && buildingCategories && buildingCategories[selectedCategory]) {
      const spaceIds = combineCategorySpaceId(buildingCategories, selectedCategory);
      const spaceList = spaceIds.map((id) => spaceMap[id]);
      setNumberOfRoomsPerFloor(getNumberOfRoomsPerFloor(spaceList));
    }
  }, [buildingCategories, selectedCategory, spaceMap]);

  const getNumberOfRoomsPerFloor = (spaceList: IRoomInfo[]): Map<string, number> => {
    const floorToRoomCountMap = new Map();

    for (const space of spaceList) {
      const spaceFloorId = space.cardAttributes.floorId;
      const currentCountForFloor = floorToRoomCountMap.get(spaceFloorId) || 0;
      floorToRoomCountMap.set(spaceFloorId, currentCountForFloor + 1);
    }

    return floorToRoomCountMap;
  };

  const manageIndoorMap = useCallback(() => {
    if (!mapData || !outdoorAzureMaps.current) {
      return;
    }

    if (!isIndoor && indoorAzureMaps.current) {
      outdoorAzureMaps.current.removeIndoorMap();
      indoorAzureMaps.current = null;
      return;
    }

    const indoorMapOptions: IIndoorMapOptions = {
      tilesetId: mapData.tilesetId,
      datasetId: mapData.datasetId,
      isSmartBuildingClient: true,
      bearing: bearing
    };
    if (!resetInProgress) {
      if (mapData.tilesetId === initializedBuildingRef.current?.tilesetId) {
        return;
      }
      if (floors.length === 0) {
        return;
      } else if (initializedBuildingRef.current?.floors.has(floors[0].id)) {
        return;
      }
    }
    if (isIndoor) {
      mapInitialized.current = false;
      dispatch(startTrackingEventAction(mapInitializationEvent));
      initializedBuildingRef.current = {
        tilesetId: mapData.tilesetId,
        floors: new Set<string>()
      };
      floors.forEach((floor) => initializedBuildingRef.current?.floors.add(floor.id));
      indoorAzureMaps.current?.toggleSensorLayerVisibility(false);
      if (!indoorAzureMaps.current) {
        indoorAzureMaps.current = outdoorAzureMaps.current.createIndoorMap(indoorMapOptions);
        indoorAzureMaps.current.subscribeToEvent(MapEvent.IndoorMapInitialized, onMapInitialization);
        indoorAzureMaps.current.subscribeToEvent(MapEvent.MapClick, handleMapClickCallback);
        indoorAzureMaps.current.subscribeToEvent(MapEvent.IndoorLevelChange, handleMapLevelChange);
      } else if (indoorAzureMaps.current) {
        indoorAzureMaps.current.updateIndoorMap(indoorMapOptions);
        if (featureFlightingValues["SpacePinAnimations"]) {
          indoorAzureMaps.current.enableSpacePinAnimations();
        }
      }

      // Azure indoor maps contains duplicated class IDs. For accessibilty, rename the first matching element ID in the DOM
      const mapElement = document.getElementById(containerElemId);
      if (mapElement) {
        for (const element of duplicatedElements) {
          const mapDuplicatedElement = mapElement.querySelector(`[id*="${element}"]`);
          if (mapDuplicatedElement) {
            mapDuplicatedElement.setAttribute("id", `"${element}_1"`);
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIndoor, mapData, floors]);

  const handleMapClickCallback = useCallback(
    (space: { id?: string; name: string }) => {
      dispatch(renderSpacePinsLayer());
      dispatch(spaceClickedAction(space));
    },
    [dispatch]
  );

  const onMapInitialization = (event: string): void => {
    dispatch(stopTrackingEventAction(mapInitializationEvent));
    mapInitialized.current = true;
    dispatch(clearSpacePinsLayer());
    if (floorRef.current) indoorAzureMaps.current?.setFloor(floorRef.current.name);
    indoorAzureMaps.current?.toggleSensorLayerVisibility(true);
    dispatch(setResetInProgress(false));
  };

  const handleMapLevelChange = (floorName: string): void => {
    if (!mapInitialized.current) return;

    const floorNum = +floorName;
    const floor = floorsRef.current.find((f) => (!isNaN(floorNum) && floorNum === +f.name) || f.name === floorName);
    if (floor) {
      dispatch(setFloor(floor.id));
      dispatch(clearPoiLayer());
      dispatch(renderSpacePinsLayer());
    }
  };

  const onFloorClick = (floor: ISpaceInfo): void => {
    if (indoorAzureMaps.current?.isFacilityMappingEmpty()) {
      indoorAzureMaps.current.refreshMap();
      handleMapLevelChange(floor.name);
    }
    indoorAzureMaps.current?.setFloor(floor.name);
  };

  const globalEventHandlers = useMemo<Record<string, IObserver>>(
    () => ({
      error: mapErrorObserver
    }),
    [mapErrorObserver]
  );

  useMapLayers(indoorAzureMaps.current, floorId, logger);
  useEffect(() => {
    const tokenFunction = (): Promise<string> => azureMapsTokenService.getAzureMapsToken();
    configurationService.getSetting("AzureMapsClientId").then(async (clientId) => {
      if (!clientId) {
        logger.logError(new Error("Azure Maps Client Id not defined in appsettings, unable to initialize map"));
        return;
      }
      const smartBuildingApiHttp = await smartBuildingApiHttpProvider();
      const azureMapDataCallback = (datasetId: string): Promise<IAzureMapsDataQueryResponse | undefined> =>
        smartBuildingApiHttp.getAzureMapsData(datasetId);
      outdoorAzureMaps.current = new OutdoorAzureMap(
        containerElemId,
        clientId as string,
        tokenFunction,
        clientTheme,
        zoomlevel,
        azureMapDataCallback,
        globalEventHandlers,
        logger
      );
      manageIndoorMap();
    });

    // We only want to run this on initialization
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalEventHandlers]);

  useEffect(() => {
    manageIndoorMap();
  }, [manageIndoorMap]);

  useEffect(() => {
    floorsRef.current = floors;
    floorRef.current = floors.find((f) => f.id === (deepLinkFloorId === undefined ? floorId : deepLinkFloorId));
  }, [floors, floorId, deepLinkFloorId]);

  return (
    <>
      <div className={classNames.mapControlPanel}>
        <FloorSelector
          selectedKey={floorId}
          floors={floors.map((f) => ({
            key: f.id,
            name: f.name,
            onClick: () => onFloorClick(f),
            availability: numberOfRoomsPerFloor?.get(f.id)
          }))}
        />
        <ZoomControl
          onZoomInClick={() => outdoorAzureMaps.current?.zoomIn()}
          onZoomOutClick={() => outdoorAzureMaps.current?.zoomOut()}
        />
      </div>
      {ReactDOM.createPortal(
        <div id={containerElemId} className={classNames.map} />,
        document.getElementById("react-app") as Element
      )}
    </>
  );
}
