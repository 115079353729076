import React from "react";
import { createSvgIcon } from "@fluentui/react-icons-mdl2";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const WindowOutlineIcon = createSvgIcon({
  // eslint-disable-next-line react/display-name
  svg: (args) => {
    return (
      <svg viewBox="0 0 24 24" fill="none" className={args.classes.svg} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.25 3C4.45507 3 3 4.45507 3 6.25V17.75C3 19.5449 4.45507 21 6.25 21H17.75C19.5449 21 21 19.5449 21 17.75V6.25C21 4.45507 19.5449 3 17.75 3H6.25ZM19.5 7H4.5V6.25C4.5 5.2835 5.2835 4.5 6.25 4.5H17.75C18.7165 4.5 19.5 5.2835 19.5 6.25V7ZM4.5 8.5H19.5V17.75C19.5 18.7165 18.7165 19.5 17.75 19.5H6.25C5.2835 19.5 4.5 18.7165 4.5 17.75V8.5Z"
          fill="currentColor"
        />
      </svg>
    );
  },
  displayName: "WindowOutlineIcon"
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const WindowFilledIcon = createSvgIcon({
  // eslint-disable-next-line react/display-name
  svg: (args) => {
    return (
      <svg viewBox="0 0 24 24" fill="none" className={args.classes.svg} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 6.25C3 4.45507 4.45507 3 6.25 3H17.75C19.5449 3 21 4.45507 21 6.25V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45507 21 3 19.5449 3 17.75V6.25ZM4.5 17.75C4.5 18.7165 5.2835 19.5 6.25 19.5H17.75C18.7165 19.5 19.5 18.7165 19.5 17.75V8.5H4.5V17.75Z"
          fill="currentColor"
        />
      </svg>
    );
  },
  displayName: "WindowFilledIcon"
});
