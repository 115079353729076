import {
  IMapFeaturesOverlayBaseProps,
  IMapFeaturesOverlayStyleProps,
  IMapFeaturesOverlayStyles
} from "./MapFeaturesOverlay.Types";
import { MapFeaturesOverlayBase } from "./MapFeaturesOverlay.Base";
import React from "react";
import { getMapFeaturesOverlayStyles } from "./MapFeaturesOverlay.Styles";
import { styled } from "@uifabric/utilities";

// eslint-disable-next-line @typescript-eslint/naming-convention
const StyledMapFeaturesOverlay = styled<
  IMapFeaturesOverlayBaseProps,
  IMapFeaturesOverlayStyleProps,
  IMapFeaturesOverlayStyles
>(MapFeaturesOverlayBase, getMapFeaturesOverlayStyles);

export function MapFeaturesOverlay(): JSX.Element {
  return <StyledMapFeaturesOverlay />;
}
