import React from "react";
import { createSvgIcon } from "@fluentui/react-icons-mdl2";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ProjectorScreenOutlineIcon = createSvgIcon({
  // eslint-disable-next-line react/display-name
  svg: (args) => {
    return (
      <svg viewBox="0 0 24 24" fill="none" className={args.classes.svg} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.5 3C2.67157 3 2 3.67157 2 4.5C2 5.15311 2.4174 5.70873 3 5.91465V13.25C3 15.3211 4.67893 17 6.75 17H11.25V19.5H7.75C7.33579 19.5 7 19.8358 7 20.25C7 20.6642 7.33579 21 7.75 21H16.25C16.6642 21 17 20.6642 17 20.25C17 19.8358 16.6642 19.5 16.25 19.5H12.75V17H17.25C19.3211 17 21 15.3211 21 13.25V5.91465C21.5826 5.70873 22 5.15311 22 4.5C22 3.67157 21.3284 3 20.5 3H3.5ZM4.5 6H19.5V13.25C19.5 14.4926 18.4926 15.5 17.25 15.5H6.75C5.50736 15.5 4.5 14.4926 4.5 13.25V6Z"
          fill="currentColor"
        />
      </svg>
    );
  },
  displayName: "ProjectorScreenOutlineIcon"
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ProjectorScreenFilledIcon = createSvgIcon({
  // eslint-disable-next-line react/display-name
  svg: (args) => {
    return (
      <svg viewBox="0 0 24 24" fill="none" className={args.classes.svg} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.5 3C2.67157 3 2 3.67157 2 4.5C2 5.15311 2.4174 5.70873 3 5.91465V13.25C3 15.3211 4.67893 17 6.75 17H11.25V19.5H7.75C7.33579 19.5 7 19.8358 7 20.25C7 20.6642 7.33579 21 7.75 21H16.25C16.6642 21 17 20.6642 17 20.25C17 19.8358 16.6642 19.5 16.25 19.5H12.75V17H17.25C19.3211 17 21 15.3211 21 13.25V5.91465C21.5826 5.70873 22 5.15311 22 4.5C22 3.67157 21.3284 3 20.5 3H3.5Z"
          fill="currentColor"
        />
      </svg>
    );
  },
  displayName: "ProjectorScreenFilledIcon"
});
