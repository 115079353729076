import React from "react";
import { createSvgIcon } from "@fluentui/react-icons-mdl2";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const WebcamOutlineIcon = createSvgIcon({
  // eslint-disable-next-line react/display-name
  svg: (args) => {
    return (
      <svg viewBox="0 0 24 24" fill="none" className={args.classes.svg} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.7454 4C20.988 4 21.9954 5.00736 21.9954 6.25V17.7546C21.9954 18.9972 20.988 20.0046 19.7454 20.0046H4.25C3.00736 20.0046 2 18.9972 2 17.7546V6.25C2 5.00736 3.00736 4 4.25 4H19.7454ZM19.7454 5.5H4.25C3.83579 5.5 3.5 5.83579 3.5 6.25V17.7546C3.5 18.1688 3.83579 18.5046 4.25 18.5046L6.999 18.504L7 15.75C7 14.8318 7.70711 14.0788 8.60647 14.0058L8.75 14H15.2447C16.1629 14 16.9159 14.7071 16.9889 15.6065L16.9947 15.75L16.994 18.504L19.7454 18.5046C20.1596 18.5046 20.4954 18.1688 20.4954 17.7546V6.25C20.4954 5.83579 20.1596 5.5 19.7454 5.5ZM15.2447 15.5H8.75C8.63165 15.5 8.53251 15.5822 8.5066 15.6927L8.5 15.75L8.499 18.504H15.494L15.4947 15.75C15.4947 15.6317 15.4125 15.5325 15.302 15.5066L15.2447 15.5ZM12 7.00046C13.6569 7.00046 15 8.34361 15 10.0005C15 11.6573 13.6569 13.0005 12 13.0005C10.3431 13.0005 9 11.6573 9 10.0005C9 8.34361 10.3431 7.00046 12 7.00046ZM12 8.50046C11.1716 8.50046 10.5 9.17203 10.5 10.0005C10.5 10.8289 11.1716 11.5005 12 11.5005C12.8284 11.5005 13.5 10.8289 13.5 10.0005C13.5 9.17203 12.8284 8.50046 12 8.50046Z"
          fill="currentColor"
        />
      </svg>
    );
  },
  displayName: "WebcamOutlineIcon"
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const WebcamFilledIcon = createSvgIcon({
  // eslint-disable-next-line react/display-name
  svg: (args) => {
    return (
      <svg viewBox="0 0 24 24" fill="none" className={args.classes.svg} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.7454 4C20.988 4 21.9954 5.00736 21.9954 6.25V17.7546C21.9954 18.9972 20.988 20.0046 19.7454 20.0046H4.25C3.00736 20.0046 2 18.9972 2 17.7546V6.25C2 5.00736 3.00736 4 4.25 4H19.7454ZM19.7454 5.5H4.25C3.83579 5.5 3.5 5.83579 3.5 6.25V17.7546C3.5 18.1688 3.83579 18.5046 4.25 18.5046L6.999 18.504L7 15.75C7 14.8318 7.70711 14.0788 8.60647 14.0058L8.75 14H15.2447C16.1629 14 16.9159 14.7071 16.9889 15.6065L16.9947 15.75L16.994 18.504L19.7454 18.5046C20.1596 18.5046 20.4954 18.1688 20.4954 17.7546V6.25C20.4954 5.83579 20.1596 5.5 19.7454 5.5ZM12 7.00046C13.6569 7.00046 15 8.34361 15 10.0005C15 11.6573 13.6569 13.0005 12 13.0005C10.3431 13.0005 9 11.6573 9 10.0005C9 8.34361 10.3431 7.00046 12 7.00046Z"
          fill="currentColor"
        />
      </svg>
    );
  },
  displayName: "WebcamFilledIcon"
});
