import { ISearchResultItem, MenuSearchClickCallback } from "./MenuSearch.Types";
import { useEffect, useState } from "react";
import { RoomSubTypes } from "@smartbuilding/adt-v2-types";
import { categoryIconMap } from "../MenuPanelProps";
import { getRooms } from "../../../redux/Selectors";
import { useSelector } from "react-redux";

type RoomSearchOptions = Partial<Record<RoomSubTypes, ISearchResultItem[]>>;

export function useRoomsSearchResultOptions(
  onClickCallback: MenuSearchClickCallback,
  buildingCategories: RoomSubTypes[]
): RoomSearchOptions {
  const rooms = useSelector(getRooms);
  const [result, setRoomSearchResults] = useState<RoomSearchOptions>({});
  useEffect(() => {
    const searchResultOptions: Partial<Record<RoomSubTypes, ISearchResultItem[]>> = {};

    buildingCategories.forEach((category) => (searchResultOptions[category] = []));
    // Add special case since Desks will be included in Offices but won't show up in building categories
    searchResultOptions.Desk = [];

    rooms.forEach((room) => {
      const friendlyName = room.cardAttributes.friendlyName ?? "";
      const resultItem: ISearchResultItem = {
        sortBy: room.name,
        filterBy: [room.name, room.type, friendlyName],
        itemData: {
          key: room.id,
          primaryText: `${room.name} ${friendlyName}`,
          icon: categoryIconMap[room.type],
          onClick: () => {
            onClickCallback(room.id, room.type);
          }
        }
      };

      searchResultOptions[room.type]?.push(resultItem);
    });

    setRoomSearchResults(searchResultOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooms]);

  return result;
}
