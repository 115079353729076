import {
  BookStatus,
  GenericSpaceCard,
  IListViewFloorInfoProps,
  IListViewFloorProps,
  ISpaceCardProps
} from "@smartbuilding/ui-components-space-card";
import {
  IDetailsPanelListViewCardProps,
  IInstantBookingConfirmationInfo,
  spaceCapabilitiesIconMap
} from "../DetailsPanelProps";
import { IRoomInfo, InstantBookingDurationCategory } from "../../../redux/Types";
import { ISpaceStatusLabelProps, SpaceStatusColor } from "@smartbuilding/ui-components-space-status";
import { PrimaryStatusValue, SpaceTypeNames, getLogicalOrder, getStatus, groupBy } from "@smartbuilding/utilities";
import { Provider, serviceIdentifiers } from "../../../serviceContainer/ServiceIdentifiers";
import React, { useEffect, useState } from "react";
import { combineCategorySpaceId, instantBookRoom } from "./useListViewCardUtilities";
import {
  getBuildingCategories,
  getBuildingName,
  getCurrentUser,
  getFloor,
  getFloors,
  getRoomMap,
  getSelectedListViewCard,
  getSpaceBusynessRuleSet,
  getWebSensorLayerEnabledArray
} from "../../../redux/Selectors";
import { getIsDetailsPanelOpen, getIsZoomed } from "../../../redux/Selectors/LayoutSelectors";
import { useDispatch, useSelector } from "react-redux";
import { ILogger } from "@smartbuilding/log-provider";
import { IRoomCardAttributes } from "@smartbuilding/room-card-service";
import { ISmartBuildingApiRoomHttp } from "@smartbuilding/smartbuilding-api-service";
import { OccupancyStatusDataValue } from "@smartbuilding/adt-v2-types";
import { SvgIcon } from "@smartbuilding/ui-components-icons";
import converter from "number-to-words";
import { getSensorData } from "@smartbuilding/signalr-redux";
import { setRoom } from "../../../redux/Actions";
import { toggleDetailsPanelAction } from "../../../redux/Actions/LayoutActionCreator";
import { useInjection } from "../../../serviceContainer/ServiceContainerProvider";

export function ListViewCard(props: IDetailsPanelListViewCardProps): JSX.Element {
  const { selectedCategory, onPanelBackClickCallback, onPanelDismissCallback } = props;
  const spaceHttpProvider = useInjection<Provider<ISmartBuildingApiRoomHttp>>(
    serviceIdentifiers.smartBuildingApiRoomHttpService
  );
  const logger = useInjection<ILogger>(serviceIdentifiers.logger);
  const loggerMsgTitle = "ListViewCard";
  const spaceBookingSubject = "Smart Building Booking";
  const spaceBookingBodyText = "<html> This meeting was booked using Smart Buildings. </html>";
  const buildingCategories = useSelector(getBuildingCategories);
  const buildingName = useSelector(getBuildingName) ?? "";
  const spaceMap = useSelector(getRoomMap);
  const floor = useSelector(getFloor);
  const floors = useSelector(getFloors);
  const roomSubTypesWithBusynessInfo = useSelector(getSpaceBusynessRuleSet);
  const roomSubTypesWithStatusInfo = useSelector(getWebSensorLayerEnabledArray);
  const sensorSpaceMap = useSelector(getSensorData);
  const dispatch = useDispatch();
  const isDetailsPanelOpen = useSelector(getIsDetailsPanelOpen);
  const isZoomed = useSelector(getIsZoomed);
  const currentUserInfo = useSelector(getCurrentUser);
  const selectedListViewCard = useSelector(getSelectedListViewCard);
  const [listViewProps, setListViewProps] = useState<IListViewFloorInfoProps>();
  const [instantBookingConfirmingSpaceId, setInstantBookingConfirmingSpaceId] = useState<string | undefined>();

  const toggleDetailsPanel = (): void => {
    dispatch(toggleDetailsPanelAction());
  };

  useEffect(() => {
    if (!isInstantBooking && !isCategoryValid) return;
    const floorInfoList: IListViewFloorProps[] = [];

    const listViewSpaceIds =
      isInstantBooking() && selectedListViewCard
        ? selectedListViewCard.selectedSpaceIds
        : combineCategorySpaceId(buildingCategories, selectedCategory);

    const spaceList = listViewSpaceIds.map((id) => spaceMap[id]);
    const listViewFloorMap = groupBy(Object.values(spaceList.map((space) => space.cardAttributes)), "floorId");

    floors.forEach((f) => {
      let cards: ISpaceCardProps[] = [];
      if (listViewFloorMap[f.id] && listViewFloorMap[f.id].length > 0) {
        cards = listViewFloorMap[f.id]
          .map((card: IRoomCardAttributes): ISpaceCardProps => {
            const status = getStatusHelper(spaceMap[card.id]);
            const details = getSpaceDetails(card);
            const spaceType = SpaceTypeNames[spaceMap[card.id].type];
            return {
              img: card.icon,
              imgAltDescription: "Room Visual",
              header: card.spaceName,
              friendlyName: card.friendlyName,
              spaceType,
              /**
               * Effective data: 8/16/2024
               * Real-time status of the space is no longer available.
               * Commenting out until we have a solution.
               */
              //status,
              details,
              icons: getCapabilitiesIcons(card),
              onClick: isInstantBooking() ? undefined : () => dispatch(setRoom(card.id)),
              spaceCardContainerAriaLabel: getAriaLabel(
                card.spaceName,
                status,
                details,
                getRoomCapabilities(card),
                spaceType
              ),
              spaceCardContainerDescribedBy: "space-card-select-button",
              bookNowCallback: isInstantBooking()
                ? () =>
                    handleBookNowCallback(
                      card,
                      selectedListViewCard?.targetStartTime,
                      selectedListViewCard?.targetEndTime
                    )
                : undefined,
              bookStatus: isInstantBooking()
                ? instantBookingConfirmingSpaceId === card.id
                  ? BookStatus.BookingConfirming
                  : BookStatus.Default
                : undefined
            };
          })
          .filter(
            (card: ISpaceCardProps) =>
              (isInstantBooking() &&
                card.status &&
                (card.status.statusLabel === PrimaryStatusValue.Available ||
                  card.status.statusLabel === PrimaryStatusValue.Occupied)) ||
              !isInstantBooking()
          )
          .sort((a, b) => {
            const logicalOrderA = getLogicalOrder(buildingCategories, a.status?.rank, a.spaceType);
            const logicalOrderB = getLogicalOrder(buildingCategories, b.status?.rank, b.spaceType);
            return logicalOrderA - logicalOrderB;
          });
      }
      const floorInfo: IListViewFloorProps = {
        floorText: getFloorText(f.id, f.name),
        items: cards.length > 0 ? cards : [{ header: "No spaces available" }],
        selected: f.id === floor?.id,
        containerAriaLabel: `${getListViewName()} ${getFloorBaseText(f.name)}`,
        isSpaceAvailable: cards.length > 0
      };
      floorInfoList.push(floorInfo);
    });

    const currListViewProps: IListViewFloorInfoProps = {
      listViewFloorInfo: floorInfoList.reverse(),
      noScrollFlag: !!instantBookingConfirmingSpaceId
    };

    setListViewProps(currListViewProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedListViewCard, selectedCategory, spaceMap, floor, floors, instantBookingConfirmingSpaceId]);

  const handleBookNowCallback = (
    cardAttributes: IRoomCardAttributes,
    targetStartTime?: Date,
    targetEndTime?: Date
  ): void => {
    if (instantBookingConfirmingSpaceId) return;
    if (targetStartTime && targetEndTime) {
      setInstantBookingConfirmingSpaceId(cardAttributes.id);
      instantBookRoom(
        spaceHttpProvider,
        buildingName,
        cardAttributes.id,
        cardAttributes.spaceName,
        spaceMap[cardAttributes.id].type,
        cardAttributes.conferenceRoomAlias ?? "",
        targetStartTime,
        targetEndTime,
        currentUserInfo?.organizerEmailAddress ?? "",
        spaceBookingSubject,
        spaceBookingBodyText,
        logger,
        loggerMsgTitle,
        setInstantBookingConfirmingSpaceId,
        handleDispatchSetInstantBookingRoom
      );
    }
  };

  const handleDispatchSetInstantBookingRoom = (
    roomId: string,
    instantBookingConfirmationInfo: IInstantBookingConfirmationInfo
  ): void => {
    dispatch(setRoom(roomId, instantBookingConfirmationInfo));
  };

  const isInstantBooking = (): boolean => {
    if (
      selectedListViewCard &&
      (selectedListViewCard.selectedCategory === InstantBookingDurationCategory.InstantBooking30MinsCategory ||
        selectedListViewCard?.selectedCategory === InstantBookingDurationCategory.InstantBooking60MinsCategory)
    )
      return true;
    return false;
  };

  const isCategoryValid = (): boolean => {
    if (buildingCategories && buildingCategories[selectedCategory]) return true;
    return false;
  };

  const getListViewName = (): string => {
    if (isInstantBooking() && selectedListViewCard) return selectedListViewCard.selectedCategory;
    return buildingCategories[selectedCategory]?.displayName ?? selectedCategory;
  };

  const getStatusHelper = (space: IRoomInfo): ISpaceStatusLabelProps | undefined => {
    if (roomSubTypesWithBusynessInfo.includes(space.type)) {
      const occupancyStatus = sensorSpaceMap[space.id]?.OccupancyStatus as string | number | boolean | undefined;
      const peopleCount = sensorSpaceMap[space.id]?.PeopleCount as number | undefined;
      const roomCapacity = space.cardAttributes.roomCapacity ?? 0;
      if (occupancyStatus === OccupancyStatusDataValue.Error) {
        return { statusLabel: "Occupancy unknown", statusColorName: SpaceStatusColor.Grey };
      }
      if (!roomCapacity || peopleCount === undefined) return undefined;
      const ratio = peopleCount / roomCapacity;
      const label = ratio < 0.4 ? "Not busy" : ratio > 0.75 ? "Very busy" : "Busy";
      const statusColorName =
        ratio < 0.4 ? SpaceStatusColor.Green : ratio > 0.75 ? SpaceStatusColor.Red : SpaceStatusColor.Orange;
      return {
        statusLabel: label,
        statusColorName: statusColorName
      };
    } else if (roomSubTypesWithStatusInfo.includes(space.type)) {
      return getStatus(space.id, sensorSpaceMap);
    } else {
      return undefined;
    }
  };

  const getSpaceDetails = (spaceData: IRoomCardAttributes): string[] => {
    const details: string[] = [];
    if (spaceData.conferenceRoomSize) {
      details.push(`${spaceData.conferenceRoomSize} person space`);
    }
    return details;
  };

  const getCapabilitiesIcons = (spaceData: IRoomCardAttributes): SvgIcon[] => {
    const icons: SvgIcon[] = spaceData.roomCapabilities
      ? Object.values(spaceData.roomCapabilities).map((capability) => spaceCapabilitiesIconMap[capability.title])
      : [];
    return icons;
  };

  const getRoomCapabilities = (spaceData: IRoomCardAttributes): string[] => {
    if (spaceData.roomCapabilities) {
      return Object.values(spaceData.roomCapabilities).map((capability) => capability.title);
    }
    return [];
  };

  const getFloorBaseText = (floorName: string): string => {
    const floorNumber = parseInt(floorName);
    return (!isNaN(floorNumber) ? converter.toOrdinal(floorNumber) : floorName) + " floor";
  };

  const getFloorText = (floorId: string, floorName: string): string => {
    const floorBaseText = getFloorBaseText(floorName);
    const selectedText = floorId === floor?.id ? " (this floor)" : "";
    return floorBaseText + selectedText;
  };

  const getAriaLabel = (
    name: string,
    status: ISpaceStatusLabelProps | undefined,
    details: string[],
    roomCapabilities: string[],
    spaceType: string
  ): string => {
    const spaceStatus = status ? `${status.statusIcon},` : "";
    const occupancyNumber = details && details[0] ? parseInt(details[0].split(" ")[0]) : 0;
    const occupancy =
      occupancyNumber > 0 ? `Seats ${occupancyNumber} ${occupancyNumber === 1 ? "person" : "people"},` : "";
    const capabilities = roomCapabilities.length ? `Contains ${roomCapabilities.join(", ")}.` : "";
    return `${spaceType} ${name}, ${spaceStatus} ${occupancy} ${capabilities}`;
  };

  return (
    <GenericSpaceCard
      navHeaderBackBtnCallback={onPanelBackClickCallback}
      navHeaderCloseBtnCallback={onPanelDismissCallback}
      navHeaderAriaLabel={props.selectedCategory}
      navHeaderBackBtnLabel="Back"
      navHeaderCloseBtnLabel="Close"
      navHeaderBackBtnDescribedBy="generic-back-button"
      navHeaderCloseBtnDescribedBy="generic-close-button"
      spaceCardPrimaryName={getListViewName()}
      isFreezingHeader={true}
      listViewProps={listViewProps}
      isZoomed={isZoomed}
      isDocked={!isDetailsPanelOpen}
      sliderCallback={toggleDetailsPanel}
      sliderAriaLabel={`Press to ${!isDetailsPanelOpen ? "open" : "close"} details panel.`}
    />
  );
}
