import React, { useCallback, useEffect, useState } from "react";
import {
  clearDetailsPanelStack,
  clearPersonScanAction,
  // clearSpacePinsLayer,
  modifyDetailsPanelStackPage,
  renderSpacePinsLayer
} from "../../redux/Actions";
import {
  getInstantBookingConfirmationInfo,
  getSelectedCategory,
  getSelectedListViewCard,
  getSelectedPerson,
  getSelectedSpace
} from "../../redux/Selectors";
import { useDispatch, useSelector } from "react-redux";
import { BookableSpaceCard } from "./BookableSpaceCard/BookableSpaceCard";
import { ILogger } from "@smartbuilding/log-provider";
import { IRoomInfo } from "../../redux/Types";
import { ListViewCard } from "./ListViewCard/ListViewCard";
import { PersonCard } from "./PersonCard/PersonCard";
import { RoomSubTypes } from "@smartbuilding/adt-v2-types";
import { SpaceCard } from "./SpaceCard/SpaceCard";
import { serviceIdentifiers } from "../../serviceContainer/ServiceIdentifiers";
import { setDetailsPanelStateAction } from "../../redux/Actions/LayoutActionCreator";
import { useDetailsPanelStyles } from "./useDetailsPanelStyles";
import { useInjection } from "../../serviceContainer/ServiceContainerProvider";

export function DetailsPanel(): JSX.Element {
  const styles = useDetailsPanelStyles();
  const logger = useInjection<ILogger>(serviceIdentifiers.logger);
  const [isOpen, setIsOpen] = useState(false);
  const selectedSpace = useSelector(getSelectedSpace);
  const selectedPerson = useSelector(getSelectedPerson);
  const selectedCategory = useSelector(getSelectedCategory);
  const selectedListViewCard = useSelector(getSelectedListViewCard);
  const selectedInstantBookingConfirmationInfo = useSelector(getInstantBookingConfirmationInfo);
  const dispatch = useDispatch();

  const dismissPanel = useCallback(() => {
    logger.logEvent("[Details Panel] Details panel has closed");
    setIsOpen(false);
    dispatch(renderSpacePinsLayer());
    dispatch(clearPersonScanAction());
    dispatch(setDetailsPanelStateAction(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isBookableRoom = (room: IRoomInfo): boolean => {
    return !!room.cardAttributes.conferenceRoomAlias || room.type === RoomSubTypes.ConferenceRoom;
  };

  const onPanelBackClickCallback = (): void => {
    dispatch(modifyDetailsPanelStackPage(undefined));
  };

  const onPanelCloseClickCallback = (): void => {
    dispatch(clearDetailsPanelStack());
  };

  const getDetailPanelComponent = (): JSX.Element => {
    if (selectedSpace) {
      if (isBookableRoom(selectedSpace) || selectedSpace.type === RoomSubTypes.FocusRoom) {
        return (
          <BookableSpaceCard
            selectedSpace={selectedSpace}
            onPanelBackClickCallback={onPanelBackClickCallback}
            onPanelDismissCallback={onPanelCloseClickCallback}
            instantBookingConfirmationInfo={selectedInstantBookingConfirmationInfo}
          />
        );
      } else {
        return (
          <SpaceCard
            selectedSpace={selectedSpace}
            onPanelBackClickCallback={onPanelBackClickCallback}
            onPanelDismissCallback={onPanelCloseClickCallback}
          />
        );
      }
    } else if (selectedPerson) {
      return (
        <PersonCard
          selectedPerson={selectedPerson}
          onPanelBackClickCallback={onPanelBackClickCallback}
          onPanelDismissCallback={onPanelCloseClickCallback}
        />
      );
    } else if (selectedCategory && selectedCategory?.indexOf("-") < 0) {
      return (
        <ListViewCard
          selectedCategory={selectedCategory}
          onPanelBackClickCallback={onPanelBackClickCallback}
          onPanelDismissCallback={onPanelCloseClickCallback}
        />
      );
    } else if (selectedListViewCard && selectedListViewCard.selectedCategory.includes("Instant")) {
      return (
        <ListViewCard
          selectedCategory={selectedListViewCard.selectedCategory}
          onPanelBackClickCallback={onPanelBackClickCallback}
          onPanelDismissCallback={onPanelCloseClickCallback}
        />
      );
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    if (selectedSpace || selectedPerson || selectedCategory || selectedListViewCard) {
      if (selectedCategory && selectedCategory?.indexOf("-") > 0) {
        dismissPanel();
      } else {
        setIsOpen(true);
      }
    } else {
      dismissPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, selectedListViewCard, selectedPerson, selectedSpace]);

  return <>{isOpen && <div className={styles.panel}>{getDetailPanelComponent()}</div>}</>;
}
