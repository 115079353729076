import * as inversify from "inversify";

import React, { useContext } from "react";

class ServiceContainerProvider {
  private container: inversify.Container | undefined;

  public constructor() {
    this.loadContainer = this.loadContainer.bind(this);
    this.getService = this.getService.bind(this);
  }

  public loadContainer(container: inversify.Container): void {
    this.container = container;
  }

  public async getService<T>(serviceIdentifier: inversify.interfaces.ServiceIdentifier<T>): Promise<T> {
    if (!this.container) {
      throw new Error("Container was not instantiated.");
    }

    const containerService = this.container.get(serviceIdentifier);
    if (typeof containerService === "function") {
      return await containerService();
    } else {
      return containerService;
    }
  }
}

export const serviceContainerProvider = new ServiceContainerProvider();

const InversifyContext = React.createContext<{ container: inversify.Container | null }>({ container: null });

interface IInversifyProviderProps {
  container: inversify.Container;
  children?: React.ReactNode;
}

export function InversifyProvider(props: IInversifyProviderProps): JSX.Element {
  return <InversifyContext.Provider value={{ container: props.container }}>{props.children}</InversifyContext.Provider>;
}

export function useInjection<T>(identifier: inversify.interfaces.ServiceIdentifier<T>): T {
  const { container } = useContext(InversifyContext);
  if (!container) {
    throw new Error(`Can't find container: ${String(identifier)}`);
  }
  return container.get<T>(identifier);
}

export function useInjectionAll<T>(identifier: inversify.interfaces.ServiceIdentifier<T>): T[] {
  const { container } = useContext(InversifyContext);
  if (!container) {
    throw new Error();
  }
  return container.getAll<T>(identifier);
}
